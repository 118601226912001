var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('SimplePage',{attrs:{"section":"jobs","pre_title":_vm.$t('Empleos'),"title":_vm.$t('Oportunidades <br> de Empleo'),"button_text":_vm.$t('Crear alerta de empleo'),"content":_vm.$t('Envianos tu información para<br>recibir emails con oportunidades de empleo<br>en ViendoMovies.')},on:{"buttonClicked":function($event){return _vm.toggleFormModal()}}}),_c('Modal',{attrs:{"is_open":_vm.form_modal_open}},[_c('app-section',{attrs:{"title":_vm.$t('Empleo'),"bootom_blue_line":true}}),_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"col-9"},[_c('Form',{attrs:{"form_type":"carreer","inputs":[{
                    type: "text",
                    placeholder: _vm.$t("Nombre"),
                    name: "name",
                    max_length: 50,
                    required: true
                },
                {
                    type: "email",
                    placeholder: _vm.$t("Correo electrónico"),
                    name: "email",
                    max_length: null,
                    required: true
                },
                {
                    type: "textarea",
                    placeholder: _vm.$t("Mensaje"),
                    name: "comment",
                    max_length: 500,
                    required: true
                },
                {
                    type: "file",
                    title: _vm.$t("Adjuntar resume"),
                    subtitle: _vm.$t("Adjuntar un DOC, DOCX, PDF, or TXT file types (1MB max)"),
                    placeholder: _vm.$t("Adjuntar resume"),
                    accept:"image/jpeg,image/png,.doc,.docx,.pdf,.txt",
                    name: "file",
                    required: true
                }],"watch_change_prop":_vm.form_modal_open}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }