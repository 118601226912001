<template>
  <div class="">
    <SimplePage
      section="jobs"
      :pre_title="$t('Empleos')"
      :title="$t('Oportunidades <br> de Empleo')"
      :button_text="$t('Crear alerta de empleo')"
      :content="$t('Envianos tu información para<br>recibir emails con oportunidades de empleo<br>en ViendoMovies.')"
      @buttonClicked="toggleFormModal()"
    ></SimplePage>
    <Modal :is_open="form_modal_open"> 
      <app-section :title="$t('Empleo')"  :bootom_blue_line="true"></app-section>
      <div class="row justify-content-center mt-5">
        <div class="col-9">
          <Form 
              form_type="carreer"
              :inputs='[{
                      type: "text",
                      placeholder: $t("Nombre"),
                      name: "name",
                      max_length: 50,
                      required: true
                  },
                  {
                      type: "email",
                      placeholder: $t("Correo electrónico"),
                      name: "email",
                      max_length: null,
                      required: true
                  },
                  {
                      type: "textarea",
                      placeholder: $t("Mensaje"),
                      name: "comment",
                      max_length: 500,
                      required: true
                  },
                  {
                      type: "file",
                      title: $t("Adjuntar resume"),
                      subtitle: $t("Adjuntar un DOC, DOCX, PDF, or TXT file types (1MB max)"),
                      placeholder: $t("Adjuntar resume"),
                      accept:"image/jpeg,image/png,.doc,.docx,.pdf,.txt",
                      name: "file",
                      required: true
                  }]'
              :watch_change_prop="form_modal_open"
          ></Form>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SimplePage from "@/components/modules/SimplePage";
import Modal from "@/components/modules/Modal";
import Form from "@/components/forms/Form";
import AppSection from "@/components/AppSection";
export default {
  name: "Jobs",
  components: {
    SimplePage,
    Modal,
    Form,
    AppSection
  },
  data: function(){
    return {form_modal_open: false}
  },
  methods: {
    toggleFormModal(){
      this.form_modal_open = !this.form_modal_open;
    }
  }
}
</script>

<style scoped>

</style>